@import "src/styles/vars_mixins";

.landing-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 0;
  .top-bg {
    @include themify(day) {
      z-index: 1;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, #ffffff6b 0%, #7cbef1 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#7cbef1",GradientType=1);
    }
    @include themify(night) {
      z-index: 0;
      background: rgb(166, 172, 175);
      background: linear-gradient(
        180deg,
        rgba(166, 172, 175, 1) 0%,
        #131a29 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a6acaf",endColorstr="#17283d",GradientType=1);
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .text-container {
    z-index: 2;
  }
  .tob-bg-illustration-dark {
    @include themify(night) {
      display: block;
      background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      top: 100px;
      -o-object-fit: unset;
      object-fit: unset;
    }
    @include themify(day) {
      display: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    @media (max-width: $xl-break) {
      display: none !important;
    }
  }
  .tob-bg-illustration-light {
    @include themify(night) {
      display: none;
    }
    @include themify(day) {
      display: block;
      opacity: 0.3;
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1100px;
    @media (max-width: $xl-break) {
      display: none !important;
    }
  }
  .tob-bg-algae {
    display: none;
    @include themify(night) {
      background-image: url("https://ecency.com/assets/hero-algae-dark.svg");
    }
    @include themify(day) {
      background-image: url("https://ecency.com/assets/hero-algae-light.svg");
    }
    background-position: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 29%;
    @media (max-width: $md-break) {
      display: block;
    }
  }
  .tob-bg-fishes {
    display: none;
    z-index: 1;
    @include themify(night) {
      background-image: url("https://ecency.com/assets/hero-fishes-dark.png");
    }
    @include themify(day) {
      background-image: url("https://ecency.com/assets/hero-fishes.svg");
    }
    background-position: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 34%;
    bottom: 10px;
    width: 200px;
    height: 280px;
    @media (max-width: $md-break) {
      display: block;
    }
    @media (max-width: $sm-break) {
      left: 23%;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 76px;
    line-height: 91px;
    text-align: center;
    @media (max-width: $md-break) {
      font-size: 31px;
      line-height: 37px;
    }
    @include themify(day) {
      @apply text-blue-dark-sky;
    }

    @include themify(night) {
      @apply text-gray-pinkish;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 59px;
    line-height: 78px;
    @media (max-width: $md-break) {
      font-weight: 800;
      font-size: 31px;
      line-height: 37px;
    }
    @include themify(day) {
      @apply text-blue-dark-sky;
    }
    @include themify(night) {
      @apply text-gray-pinkish;
    }
  }
  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 135%;
    margin-bottom: 8px;
    @include themify(night) {
      @apply text-gray-light;
    }
    @media (max-width: $md-break) {
      font-weight: 300;
      font-size: 15px;
      line-height: 25px;
    }
  }
  ul {
    list-style: none;
  }
  .link-read-more {
    font-size: 18px;
    line-height: 27px;
  }
  .no-break {
    white-space: nowrap;
    font-weight: 300;
    font-size: 24px;
    line-height: 135%;
    @media (max-width: $md-break) {
      font-weight: 300;
      font-size: 15px;
      line-height: 25px;
    }
  }
  .sections {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
  }
  .first-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    @include themify(day) {
      @apply text-blue-dark-sky;
      background-image: url('https://ecency.com/assets/illustration-hero-day.webp');
    }
    @include themify(night) {
      background-image: url('https://ecency.com/assets/illustration-hero.webp');
      @apply text-gray-pinkish;
    }
    @media (max-width: $lg-break) {
      background-image: none !important;
    }

    p {
      font-weight: 300;
      font-size: 24px;
      line-height: 135%;
      text-align: center;
      display: inline-block;
      &:last-child {
        padding-left: 0.5rem;
      }
      @media (max-width: $md-break) {
        font-size: 18px;
        display: block;
        line-height: 27px;
        &:last-child {
          padding-left: 0;
          //line-height: 70%;
        }
      }
    }
    .get-started {
      width: 140px;
      height: 56px;
      background-color: $blue;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @apply text-white;
      border-radius: 30px;
      border: none;
      outline: none;
    }
    .link-btn {
      @apply text-white;
    }
    .scroll-down {
      height: 46px;
      width: 46px;
      position: absolute;
      bottom: 30px;
      background-color: transparent;
      border: none;
      outline: none;
      animation: bounce 0.7s infinite alternate;
      -webkit-animation: bounce 0.7s infinite alternate;
      left: 48%;
      @media (max-width: $sm-break) {
        left: 44%
      }
    }
  }

  .part-top {
    height: 650px;
    width: 100%;
    padding-bottom: 75px;
    @media (max-width: $md-break) {
      padding-bottom: 0px;
    }
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      max-width: 1200px;
      width: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @media (min-width: $md-break) {
        height: 550px;
        ul > li:first-of-type {
          padding-right: 4rem;
        }
        ul > li:last-of-type {
          padding-left: 4rem;
        }
      }
      @media (max-width: $md-break) {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 441px;
      }
      img {
        margin: auto 3% auto auto;
        height: 90%;
        width: auto;
        @media (max-width: $md-break) {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          width: auto;
          height: 200px;
        }
      }
      .text-group {
        width: 52%;
        height: 100%;
        max-width: 455px;
        max-height: 300px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: auto auto auto 3%;
        visibility: visible !important;
        @media (max-width: $md-break) {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          margin: 0 2rem -7rem 2rem;
          width: auto;
          text-align: center;
        }

        .link-read-more {
          @media (max-width: $md-break) {
            display: none;
          }
        }
      }
    }
  }

  .part-bottom {
    height: 450px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include themify(night) {
      @apply bg-dark-600;
    }
    @include themify(day) {
      @apply bg-blue-faded;
    }
    @media (max-width: $lg-break) {
      height: 700px !important;
    }
    .inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      width: 100%;
      max-width: 1200px;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      .text-group {
        width: 50%;
        height: 100%;
        max-height: 300px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: auto auto auto 3%;
        visibility: visible !important;
        @media (max-width: $xxl-break) {
          width: 30%;
          p {
            line-height: 140%;
            max-width: 84%;
            @media (max-width: $md-break) {
              max-width: 100%;
              line-height: 27px;
            }
          }
        }
        @media (max-width: $md-break) {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          margin: 0;
          width: auto;
          text-align: center;
        }
      }
    }
  }

  .second-section {
    .part-bottom h2 {
      @media (max-width: $sm-break) {
        margin-top: -7rem;
      }
      @include themify(night) {
        @apply text-gray-pinkish;
      }
    }
    p {
      @include themify(night) {
        @apply text-gray-light;
      }
    }
    height: auto;
    .part-top {
      height: auto;
      @media (max-width: $sm-break) {
        height: 45rem;
      }
      .inner > img {
        max-height: 330px;
        @media (min-width: $lg-break) {
          width: 372px !important;
          height: 442px !important;
          max-height: unset;
        }
      }
      @include themify(night) {
        background-color: #131a29;
      }
      .inner {
        @media (max-width: $md-break) {
          flex-direction: column;
          .text-group {
            margin-left: 0 !important;
            text-align: center !important;
            align-items: center !important;
            h2 {
              margin-top: 1rem;
            }
          }
        }
      }
      .inner > .text-group {
        @media (min-width: $sm-break) {
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          width: 400px;
          text-align: start;
          margin: 0px;
          margin-left: 2rem;
        }
        @media (min-width: $sm-break) {
          p {
            width: 75%
          }
        }
        @media (min-width: $md-break) {
          p {
            width: 100%
          }
        }
        @media (max-width: $md-break) {
          height: auto !important;
          max-height: unset;
          img {
            margin-top: 4rem;
          }
        }
        @media (min-width: $lg-break) {
          margin: 0;
          margin-left: 2rem;
        }
        @media (max-width: $md-break) {
          margin: 0;
          margin-left: 59px;
        }
      }
      @media (max-width: $sm-break) {
        img {
          margin-top: 4rem;
        }
        .inner {
          height: auto;
        }
      }
    }
    .part-bottom {
      height: auto;

      @media (max-width: $lg-break) and (min-width: $md-break) {
        min-height: 600px;
        .landing-floating-image {
          margin: -32rem 0 0 0 !important;
          width: 499px !important;
          height: 389px !important;
        }
      }
      .inner {
        height: auto;
                justify-content: space-evenly;
        .text-group {
          width: 45%;
          margin-top: 4rem;
          margin-bottom: 4rem;
          -webkit-box-pack: center !important;
              -ms-flex-pack: center !important;
                  justify-content: center !important;
        }
        .image-wrapper {
          width: 50%;
          height: 100%;
          .landing-floating-image {
            width: 577px;
            height: 445px;
            margin-top: -10rem;
            margin-right: -5rem;
            @media (max-width: $md-break) {
              width: 342px;
              height: 264px;
            }
          }
        }
        @media (min-width: $md-break) {
          width: 100% !important;
          height: auto !important;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          .text-group {
            width: 38%;
            height: auto;
            margin: 4rem 1rem;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            text-align: start;
            max-width: unset;
          }
          .image-wrapper {
            width: 45%;
            height: auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            .landing-floating-image {
              margin: -10rem 0 0 0;
            }
          }
        }
        @media (max-width: 767px) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
          width: 100% !important;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .text-group {
            width: 100%;
          }
          .image-wrapper {
            width: 100%;
            margin: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .landing-floating-image {
              width: 300px;
              height: 350px;
              margin: -2rem 0 0 0;
            }
          }
        }
        @media (max-width: $sm-break) {
          height: 475px;
          .text-group {
            margin-top: 0;
            margin-bottom: -3rem;
            padding: 0 1rem;
            max-height: 220px;
            -webkit-box-pack: start !important;
                -ms-flex-pack: start !important;
                    justify-content: flex-start !important;
          }
          .image-wrapper {
            height: 400px;
            .landing-floating-image {
              margin: -19rem 0 0 0;
              max-width: 80%;
              max-height: 90%;
            }
          }
        }
      }
    }
  }

  .third-section {
    height: auto;
    .part-top {
      @include themify(night) {
        background-color: #131a29;
      }
      height: auto;

      @media (max-width: $sm-break) {
        height: 45rem !important;
      }

      .inner > .img-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 44%;
        @media (max-width: $md-break) {
          width: 40%;
        }
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: auto;
        margin-left: 0;
        margin-left: 0;
        .decentralization-img {
          width: 75%;
          height: auto;
          margin: 0;
          max-height: 300px;
        }
      }
      .inner > .text-group {
        width: 52%;
        margin: 0 1rem !important;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: start;
      }
      @media (max-width: 766px) {
        height: auto;
        .inner {
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          width: 100%;
          margin: 0;
          padding: 0;
          height: auto;
          .img-wrapper {
            width: 100%;
            height: 300px;
            margin-top: 3rem;

            .decentralization-img {
              height: 90%;
              width: auto;
            }
          }
          .text-group {
            height: 450px;
            width: 100%;
            margin: 0!important;
            padding: 4rem 1rem;
            max-height: unset;
            text-align: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
          }
        }
      }
      @media (max-width: $sm-break) {
        .inner {
          .img-wrapper {
            width: 90%;
            height: auto;
            .decentralization-img {
              max-height: 270px;
              height: auto;
              width: 80%;
            }
          }
          .text-group {
            height: 320px;
          }
        }
      }
    }
    .part-bottom {
      width: 100%;
      height: auto;
      min-height: 400px;
      @media (max-width: $md-break) and (min-width: $sm-break) {
        min-height: 600px;
      }
      .inner {
        width: 100%;
        height: auto;
        margin-bottom: 4rem;
        margin-top: 4rem;
        @media (max-width: $md-break) {
          margin-bottom: 0px;
          margin-top: 0px;
        }
        -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
        .text-group {
          max-width: 45%;
          padding: 1rem;
          width: auto;
          min-width: unset;
          height: auto;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          text-align: start;
        }
        .img-wrapper {
          width: 50%;
          margin: 0;
          padding: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          .mechanic {
            width: 571px;
            height: 460px;
            margin: 0;
            margin-top: -240px;
            @media (max-width: $md-break) {
              width: 327px;
              height: 263px;
              margin: 0;
              margin-top: -160px;
            }
          }
        }
        @media (max-width: 766px) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
          height: auto;
          .text-group {
            margin: 0;
            padding: 3rem 1rem 0;
            width: 100%;
            height: fit-content;
            max-width: unset;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            text-align: center;
            p {
              width: -webkit-fill-available;
              span {
                display: block;
              }
            }
          }
          .img-wrapper {
            width: 100%;
            height: auto;
            .mechanic {
              margin-top: 2rem;
              width: 90%;
              height: auto;
              max-width: 340px !important;
              max-height: 250px;
            }
          }
        }
      }
      @media (max-width: $sm-break) {
        height: 369px;
        .inner {
          .text-group {
            p {
              line-height: 25px;
              font-size: 15px;
            }
          }
          .img-wrapper {
            width: 100%;
            .mechanic {
              margin-top: -9rem;
              width: 74%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .fourth-section {
    height: auto;

    @media (min-width: $md-break) {
      justify-content: flex-end;
    }
    .part-top {
      height: 940px;
      @include themify(night) {
        background-color: #161D26;
      }
      .inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
      }
      .fish-container {
        position: absolute;
        top: 0;
        left: 0;
        right: unset;
        bottom: o;
        width: 200px;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
        &:last-of-type {
          right: 0;
          left: unset;
        }
        .fish {
          width: 152px;
          height: auto;
          position: absolute;
        }
        .one {
          width: 79px;
          height: auto;
          right: 40%;
          top: 110px;
        }
        .two {
          width: 152px;
          height: auto;
          right: 23%;
          bottom: 3px;
        }
        .three {
          width: 108px;
          height: auto;
          left: 30%;
          top: 70px;
        }
        .four {
          width: 110px;
          height: auto;
          left: 10%;
          bottom: 100px;
        }
        .five {
          width: 66px;
          height: auto;
          left: 10%;
          bottom: 50%;
        }
        @media (max-width: $lg-break) {
          .fish {
            display: none;
          }
          .five {
            display: inline-block;
          }
        }
        @media (min-width: $lg-break) {
          .five {
            display: none;
          }
        }
        @media (max-width: $sm-break) {
          -webkit-transform: scale(0.7);
          -ms-transform: scale(0.7);
          transform: scale(0.7);
        }
      }
      .mask {
        @include themify(night) {
          display: none;
        }
        @include themify(night) {
          z-index: 1;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 940px;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          pointer-events: none;
        }
      }
      ul {
        list-style: none;
        width: 100%;
        height: 50%;
        max-width: 778px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        z-index: 2;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          width: 50%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        h3 {
          font-weight: bold;
          font-size: 59px;
          line-height: 78px;
          @include themify(night) {
            @apply text-white;
          }
          @include themify(day) {
            color: $blue;
          }
          @media (max-width: $lg-break) {
            font-weight: 800;
            font-size: 31px;
            line-height: 37px;
          }
        }
        p {
          font-weight: 300;
          font-size: 24px;
          line-height: 135%;
          color: inherit;
          @media (max-width: $lg-break) {
            font-weight: 300;
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
      @media (max-width: $lg-break) {
        height: 600px;
      }
    }
    .part-bottom {
      height: 1140px;
      overflow: hidden;
      @include themify(night) {
        background: #161d26;
        background: -o-linear-gradient(180deg, #161d26 0%, #4d5356 100%);
        background: linear-gradient(180deg, #161d26 0%, #4d5356 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#161d26",endColorstr="#4d5356",GradientType=1);
      }
      @include themify(day) {
        background: rgb(196,219,235);
        background: -o-linear-gradient(273deg, rgba(196,219,235,1) 0%, rgba(113,180,255,1) 100%);
        background: linear-gradient(177deg, rgba(196,219,235,1) 0%, rgba(113,180,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c4dbeb",endColorstr="#71b4ff",GradientType=1);

      }
      position: relative;

      .download-fishes {
        display: inline-block;
        position: absolute;
        &.left-fishes {
          left: -18%;
          top: 14%;
          @media (max-width: $lg-break) {
            left: -22%;
            top: 9%;
            -webkit-transform: scale(0.5);
                -ms-transform: scale(0.5);
                    transform: scale(0.5);
          }
        }
        &.right-big {
          -webkit-transform: rotate(-23deg);
              -ms-transform: rotate(-23deg);
                  transform: rotate(-23deg);
          bottom: 0;
          @media (max-width: $lg-break) {
            -webkit-transform: rotate(-23deg) scale(0.5);
                -ms-transform: rotate(-23deg) scale(0.5);
                    transform: rotate(-23deg) scale(0.5);
          }
        }
        &.right-small {
          bottom: 150px;
          right: 110px;
          @media (max-width: $lg-break) {
            -webkit-transform: scale(0.5);
                -ms-transform: scale(0.5);
                    transform: scale(0.5);
            bottom: 103px;
            right: 59px;
          }
        }
        &.right-dark-fishes {
          display: none;
          bottom: 5%;
          -webkit-transform: scale(1.5);
              -ms-transform: scale(1.5);
                  transform: scale(1.5);
          @media (max-width: $lg-break) {
            -webkit-transform: scale(1);
                -ms-transform: scale(1);
                    transform: scale(1);
            right: 5%;
          }
        }
        @include themify(night) {
          &.right-small, &.right-big {
            display: none;
          }
          &.right-dark-fishes {
            display: inline-block;
            @media (max-width: 766px) {
              display: none;
            }
          }
        }
      }

      .phone-bg {
        position: absolute;
        height: 740px;
        width: 375px;
        display: none;
        left:100px;
        top:auto;
        bottom:auto;
        z-index: 1;
        @include themify(day) {
          @media (min-width: $lg-break) {
            &.phone-light-pc {
              display: block;
            }
          }
          @media (max-width: $lg-break) {
            &.phone-light-tablet {
              display: block;
            }
          }
        }
        @include themify(night) {
          @media (min-width: $lg-break) {
            &.phone-dark-pc {
              display: block;
            }
          }
          @media (max-width: $lg-break) {
            &.phone-dark-tablet {
              display: block;
            }
          }
        }

        @media (max-width: $lg-break) {
          height: 450px;
          width: auto;
          padding: 3rem 1rem;
          left: 130px;
        }
        @media (max-width: 766px) {
          display: none !important;
        }

      }

      .bubble-bg {
        position: absolute;
        max-height: 59px;
        max-width: 59px;
        display: inline-block;
        z-index: 0;
        &.bubble-left-top {
          top: -5%;
          left: -15%;
        }
        &.bubble-left-bottom {
          bottom: 25%;
          left: -15%;
        }
        &.bubble-center {
          top: -7%;
          left: 62%;
        }
        &.bubble-right-bottom {
          bottom: -7%;
          right: -12%;
        }
        &.bubble-right-top {
          top: 6%;
          right: -12%;
        }
        @include themify(night) {
          display: none;
        }
      }

      @media (max-width: 766px) {
        height: 500px;
        .phone-bg, .bubble-bg, .download-fishes {
          display: none;
        }
        @include themify(night) {
          background: rgb(23,30,38);
          background: -o-linear-gradient(273deg, rgba(23,30,38,1) 0%, rgba(71,77,84,1) 100%);
          background: linear-gradient(177deg, rgba(23,30,38,1) 0%, rgba(71,77,84,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171e26",endColorstr="#474d54",GradientType=1);

          @media (max-width: $sm-break) {
            background: -o-linear-gradient(180deg, #A6ACAF 0%, #17283D 100%);
            background: linear-gradient(180deg, #A6ACAF 0%, #17283D 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#A6ACAF",endColorstr="#17283D",GradientType=1);
            }
        }
        @include themify(day) {
          background: rgb(196,219,235);
          background: -o-linear-gradient(273deg, rgba(196,219,235,1) 0%, rgba(113,180,255,1) 100%);
          background: linear-gradient(177deg, rgba(196,219,235,1) 0%, rgba(113,180,255,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c4dbeb",endColorstr="#71b4ff",GradientType=1);
        }
        .inner > span {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          height: 154px;
          @include themify(night) {
            background-image: url("https://ecency.com/assets/download-algae-dark.png");
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-position-x: center;
            background-size: cover;
          }
          @include themify(day) {
            background-image: url("https://ecency.com/assets/download-algae.png");
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-position-x: center;
            background-size: cover;
          }
        }
      }
      .inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        height: 719px;
        z-index: 2;
        .text-group {

          @media (min-width: 992px) and (max-width: 1186px) {
            position: relative;
            left: 94px;
          }

          margin: 0;
          width: 58%;
          max-width: 756px;
          z-index: 2;
          h2 {
            @include themify(night) {
              @apply text-gray-pinkish;
            }
            @include themify(day) {
              color: $blue;
            }
          }
          p {
            font-weight: 300;
            font-size: 24px;
            line-height: 140% !important;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            line-height: 2;
            @include themify(night) {
              @apply text-gray-light;
            }
            @include themify(day) {
              color: $blue;
            }
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            img {
              margin-right: 0.7rem;
              width: 20px;
              height: 25px;
            }
          }
          a {
            font-weight: 300;
            font-size: 24px;
            line-height: 180%;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            line-height: 2;
            @include themify(night) {
              @apply text-gray-light;
            }
            @include themify(day) {
              color: $blue;
            }
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            img {
              margin-right: 0.7rem;
              width: 20px;
              height: 25px;
            }
          }
          @media (max-width: $lg-break) {
            -webkit-box-pack: center!important;
                -ms-flex-pack: center!important;
                    justify-content: center!important;
            padding: 0 50px;
            width: 43%;

          }
          @media (max-width: 766px) {
            margin: 1rem;
            padding: unset;

            h2 {
              font-weight: 800;
              font-size: 31px;
              line-height: 37px;
              text-align: center;
            }
            p,
            a {
              font-weight: 300;
              font-size: 15px;
              line-height: 25px;
              z-index: 1;
            }
            a {
              margin-top: 1rem;
            }

          }
        }
        @media (max-width: $lg-break) {
          -webkit-box-pack: end;
              -ms-flex-pack: end;
                  justify-content: flex-end;
          height: 100%;
          padding-left: 2rem;

          span:last-of-type {
            display: none;
          }
          .text-group {
            width: 100%;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            text-align: start;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;
            width: 60%;
            p, a {
              font-weight: 300;
              font-size: 15px;
              line-height: 140%;
            }
            a {
              line-height: 160%;
              margin-bottom: 1rem;
            }
            .windows {
              display: none;
            }
          }
        }
        @media (max-width: 766px) {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          padding-left: unset;
          .text-group {
            width: 100%;

            -webkit-box-pack: center;

                -ms-flex-pack: center;

                    justify-content: center;
            text-align: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            p, a {
              font-weight: unset;
              line-height: 25px;
              font-size: 15px;
            }
            a {
              line-height: unset;
              margin-bottom: unset;
            }
          }
        }
        @media (max-width: $sm-break) {
          span:last-of-type {
            height: 50px;
          }
        }
      }
    }
  }

  .fifth-section {
    @include themify(day){
      margin-top: 9rem;
    }
    .part-top {
      @include themify(night) {
        background-color: #171e26;
        z-index: 1;
      }
      .inner {
        width: 100%;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
      .text-group {
        width: 45%;
        height: 100%;
        max-height: unset;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        text-align: start;
        max-width: 600px;
      }
      .our-history {
        width: 45%;
        height: auto;
        margin: 0;
      }
    }
    .part-bottom {
      @include themify(night) {
        z-index: 1;
        background-color: #202834;
      }
      .inner {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        @media (max-width: $md-break) {
          justify-content: space-evenly;
        }
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        h2 {
          @include themify(night) {
            @apply text-gray-pinkish;
          }
        }
        p {
          @include themify(night) {
            @apply text-gray-light;
          }
          b {
            font-weight: 700;
          }
        }
        .our-vision {
          width: 368px;
          height: 425px;
          margin: 1rem ;
          padding-left: 1rem;
          margin-top: -52px;

          @media (max-width: $md-break) {
            padding-left: 0rem;
            width: 202px;
            height: 245px;
          }
        }
        .text-group {
          max-width: unset;
          margin-right: 0;
          width: 55%;
          max-height: unset;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          text-align: start;
          height: 100%;
          margin-right: 2rem;
          @media (max-width: $sm-break) {
            margin-top: 1rem !important;
          }
        }
      }
    }

    @media (max-width: 766px) {
      height: 1395px;
      margin: 0;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      .part-top {
        height: 1200px;
        margin: 0;
        .inner {
          margin: 0;
          height: 1200px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
          -ms-flex-pack: distribute;
          justify-content: space-around;
          @media (max-width: $md-break)  {
            height: auto;
          }

          .text-group {
            width: 100%;
            max-width: unset;
            margin: 2rem 1rem;
            padding: 1rem;
            height: auto;
            max-width: unset;
            height: auto;
            max-height: unset;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            text-align: center;
            @media (max-width: $sm-break) {
              margin: 2rem 1rem 22rem;
            }

          }
          .our-history {
            width: 250px;
            margin-top: 17rem;
            height: auto;
            @media (max-width: $sm-break) {
              width: 100%;
            }
            @media (max-width: $md-break) and (min-width: $sm-break){
              width: 70%;
              margin-top: 2rem;
            }
          }
        }
      }
      .part-bottom {
        height: 600px;
        margin: 0;
        .inner {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .our-vision {
            max-width: 270px;
            height: auto;
            width: 70%;
            margin: -25rem 0;
          }
          .text-group {
            width: 100%;
            margin: -10rem 0;
            padding: 0 1rem;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            text-align: center;
          }

          @media (max-width: $md-break) and (min-width: $sm-break) {
            height: 100%;
            .our-vision {
              margin: 0;
              margin-top: 3rem;
            }
            .text-group {
              margin: 0;
              margin-top: 2rem;
            }
          }
        }
      }
    }
    @media (max-width: $sm-break) {
      height: 1300px;
      .part-top {
        height: 800px;
        .inner {
          height: 800px;
          .text-group {
            margin-top: 2rem;
            p:first-of-type {
              margin-top: 0;
            }
          }
        }
      }
      .part-bottom > .inner > .our-vision {
        width: 27%;
        margin: 0;
        margin-top: -27rem;
        @media (max-width: 375px) {
          width: 50% !important;
        }
        @media (max-width: 420px) and (min-width:375px) {
          width: 35%;
        }
      }
      .part-bottom {
        height: 500px;
        @media (max-width: $sm-break) {
          margin-top: 10rem;;
        }
      }
    }
  }

  .sixth-section {
    height: auto;
    margin-top: 9rem;
    @include themify(night) {
      background-color: #171e26;
    }
    @include themify(day) {
      @apply bg-white;
    }
    .part-top {
      height: 750px;
      .inner {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
        .text-group {
          margin: 1rem;
          width: 33%;
          height: 80%;
          max-height: unset;
          h2 {
            margin-bottom: 2rem;
          }
          ul {
            width: 370px;
            height: 520px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin: 0;
            padding: 0;
            img {
              margin: 0;
              padding: 0;
              border-radius: 50%;
            }
            li {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-direction: row;
              flex-direction: row;
              -webkit-box-align: start;
              -ms-flex-align: start;
              align-items: flex-start;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              height: 80px;
              margin-bottom: 1rem;

              .text-wrapper {
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                margin-left: 1rem;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                p,
                a {
                  margin: 0;
                  padding: 0;
                  font-weight: 300;
                  font-size: 24px;
                  line-height: 140%;
                }
              }
              &.last-element {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                margin-top: 2rem;
                a {
                  margin-bottom: 1rem;
                  font-weight: 300;
                  font-size: 24px;
                  line-height: 140%;
                }
              }
            }
          }
          ul > li:last-of-type {
            padding-left: 0;
          }
        }
        .image-container {
          width: 45%;
          margin: 1rem;
        }
        .junior,
        .senior {
          display: none;
        }
        .together {
          display: inline-block;
          height: auto;
          width: 100%;
          margin-left: -6rem;
        }
      }
    }
    .part-bottom {
      height: 750px !important;
      max-height: unset;
      @include themify(night) {
        background: #161d26;
      }
      @include themify(day) {
        background: rgb(232, 239, 249);
        background: -o-radial-gradient(circle, #b1bfde 0%, #5a88c1 100%);
        background: radial-gradient(circle, #b1bfde 0%, #5a88c1 100%);
      }
      background-size: 100% 100%;
      background-position: center;
      position: relative;
      .left-fishes {
        position: absolute;
        top: 90px;
        left: 50px;
        background-image: url("https://ecency.com/assets/left-fishes.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 270px;
        width: 270px;
        @media (max-width: $xxxl-break) {
          display: none;
        }
      }
      .main-fish {
        position: absolute;
        right: 0px;
        bottom: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 484px;
        height: 575px;
      }
      .inner {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        margin: 0;
        height: 100%;
        a,
        p {
          font-size: 18px;
          line-height: 27px;
          @include themify(night) {
            @apply text-gray-light;
          }
          @include themify(day) {
            @apply text-white;
          }
          margin: 0;
          cursor: pointer;
        }
        .links-and-form {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          width: 100%;
          height: auto;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0 0 4rem 0;
          .links {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            width: 40%;
            height: 162px;
            ul {
              padding: 0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              width: 100%;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
            }
          }
          .subscribe-form {
            height: 226px;
            width: 60%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            p{
              @include themify(night) {
                @apply text-gray-pinkish;
              }
              @include themify(day) {
                @apply text-white;
              }
            }
            h2 {
              font-weight: bold;
              font-size: 43px;
              line-height: 51px;
              @include themify(night) {
                @apply text-gray-pinkish;
              }
              @include themify(day) {
                color: $blue;
              }
            }
            form {
              margin: 1rem 0;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              input {
                @apply bg-blue-dark-sky;
                -webkit-backdrop-filter: blur(500px);
                backdrop-filter: blur(500px);
                border-radius: 50px 0 0 50px;
                height: 50px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                outline: none;
                border: none;
                width: 70%;
                max-width: 295px;
                padding-left: 2rem;
                @apply text-white;
                &::-webkit-input-placeholder {
                  @apply text-white;
                }
                &::-moz-placeholder {
                  @apply text-white;
                }
                &:-ms-input-placeholder {
                  @apply text-white;
                }
                &::-ms-input-placeholder {
                  @apply text-white;
                }
                &::placeholder {
                  @apply text-white;
                }
              }
              button {
                @apply bg-blue-dusk;
                border-radius: 0 50px 50px 0;
                height: 50px;
                box-sizing: border-box;
                outline: none;
                border: none;
                width: 30%;
                max-width: 113px;
                @apply text-white;
                position: relative;
                span {
                  width: 80%;
                  height: 100%;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  margin-left: 0.5rem;
                }
              }
            }
            .socials {
              margin: 1rem 0;
              ul {
                margin: 1rem 0;
                padding: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                li {
                  margin-right: 1.5rem;
                }
              }
            }
          }
        }
        .site-icon {
          position: absolute;
          bottom: 15px;
          right: 0;
          left: 0;
          height: 95px;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          img {
            height: 48px;
            width: 48px;
          }
          .copy-right {
            margin-top: 1rem;
          }
        }
      }
    }

    @media (max-width: $xxl-break) {
      .part-bottom {
        background: rgb(89, 163, 254);
        background: -o-linear-gradient(
          89deg,
          rgba(89, 163, 254, 1) 0%,
          rgba(201, 234, 251, 1) 100%
        );
        background: linear-gradient(
          1deg,
          rgba(89, 163, 254, 1) 0%,
          rgba(201, 234, 251, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#59a3fe",endColorstr="#c9eafb",GradientType=1);

        height: 1000px !important;
        .main-fish {
          width: 600px;
          height: 750px;
        }
        .inner {
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          .links-and-form {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            width: 70%;
            height: 800px;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding-left: 1rem;
            margin-top: -200px;
            .links {
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              margin-top: 3rem;
              min-height: 350px;
              -webkit-box-align: start;
              -ms-flex-align: start;
              align-items: flex-start;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              ul {
                margin-bottom: 0;
                li {
                  margin-bottom: 0.5rem;
                }
              }
            }
            .subscribe-form {
              h2 {
                font-size: 38px;
                line-height: 45px;
              }
            }
          }
        }
      }
    }
    @media (max-width: $xl-break) {
      .part-bottom > .inner > .links-and-form > .links {
          .first-column,
          .second-column {
            height: auto;
            li {
              margin-top: 1rem;
            }
          }
          height: auto;

      }
    }

    @media (max-width: $lg-break) {
      .part-top {
        .inner {
          .text-group {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: flex-start;
            margin-right: 0;
            height: 55%;
            h2 {
              text-align: start;
              @include themify(night) {
                @apply text-gray-pinkish;
              }
              @include themify(day) {
                @apply text-white;
              }
            }
            ul {
              width: 155px;
              height: 80%;
              li {
                height: max-content;
                width: max-content;
                margin-left: 0.5rem;
                margin-bottom: 0.5rem;
                &.last-element {
                  margin-top: 1.5rem;
                  -webkit-box-align: start;
                  -ms-flex-align: start;
                  align-items: flex-start;
                  -webkit-box-pack: start;
                  -ms-flex-pack: start;
                  justify-content: flex-start;
                  width: 180px;
                  a {
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: start;
                  }
                }
                img {
                  height: 44px;
                  width: 44px;
                }
                .text-wrapper {
                  margin-left: 0.5rem;
                  a,
                  p {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 24px;
                  }
                }
              }
            }
          }
          .image-container {
            height: 50%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: distribute;
            width: 50%;
            margin: 0 0.5rem 0 0;
            img {
              -ms-grid-column-align: center;
              justify-self: center;
              margin: 0;
            }
            .together {
              display: none;
            }
            .junior,
            .senior {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              width: auto;
            }
            .junior {
              height: 112px;
            }
            .senior {
              height: 195px;
              margin-top: -30px;
            }
          }
        }
      }
      .part-bottom {
        overflow-x: hidden;
        .main-fish {
          width: 500px;
          height: 700px;
          overflow-x: hidden;
          right: -78px;
        }
        .inner {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .links-and-form {
            width: 90%;
            padding: 0;
            .links {
              height: auto;
            }
            .subscribe-form {
              width: 100%;
              height: auto;
              form {
                width: 97%;
                input {
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 23px;
                  padding-left: 1rem;
                }
                button {
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 23px;
                }
              }
            }
          }
          .site-icon {
            width: 100%;
            height: 120px;
            text-align: center;
            p {
              max-width: 90%;
            }
          }
        }
      }
    }
    @media (max-width: $sm-break) {
      .part-top {
        height: 540px;
        .inner > .text-group {
          height: 400px;
          ul {
            margin-left: -20px;
          }
          h2 {
            width: 180px;
            margin-right: -40px;
          }
        }
        .inner > .image-container {
          height: 340px;
          flex-direction: column;
          justify-content: space-around;
        }
        .inner > .image-container > .junior {
          height: 82px !important;
          margin-right: -20px;
        }
        .inner > .image-container > .senior {
          margin-right: -20px;
          height: 165px !important;
        }
      }
      .part-bottom {
        .inner > .links-and-form > .subscribe-form > h2 {
          font-weight: 800;
          font-size: 31px;
          line-height: 37px;
          @include themify(night) {
            @apply text-gray-pinkish;
          }
        }

        .main-fish {
          right: -114px;
          width: 330px;
          height: 440px;
        }
      }
    }
  }

  @keyframes bounce {
    from {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
}

.w-88 {
  width: 88%;
}
